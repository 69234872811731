<template>
  <div>
    <v-card-text>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allStates"
        v-model="state_filter"
        item-text="state_name"
        item-value="id"
        label="State*"
        :return-object="true"
        required
        :rules="[rules.required('State')]"
        @change="loadDistricts(state_filter.id, 'Active')"
      ></v-autocomplete>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allDistricts"
        v-model="district_filter"
        item-text="district_name"
        item-value="id"
        label="District*"
        required
        :return-object="true"
        :rules="[rules.required('District')]"
        @change="loadMandals(district_filter.id, 'Active')"
      ></v-autocomplete>
      <v-autocomplete
        class="d-inline-flex pa-2"
        dense
        solo
        clearable
        :items="allMandals"
        v-model="mandal_filter"
        item-text="mandal_name"
        item-value="id"
        label="Mandal*"
        required
        :return-object="true"
        :rules="[rules.required('Mandal')]"
        @change="loadVillages(mandal_filter.id, 'Active')"
      ></v-autocomplete>
      <v-data-table
        v-model="selectedVillages"
        :headers="headers"
        :items="allVillages"
        fixed-header
        height="400px"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
        dense
        item-key="id"
        :search="search"
        show-select
      >
        <template v-slot:top>
          <v-toolbar flat dense>
            <v-toolbar-title>Villages</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn
              color="error"
              outlined
              :loading="isLoading"
              v-if="selectedVillages.length > 0"
              @click="deleteSelectedHandler"
              >Delete Selected</v-btn
            >
            <v-spacer></v-spacer>

            <v-chip class="ma-2" color="green" text-color="white">
              Total
              <v-avatar right class="green darken-4">
                {{ allVillages.length }}
              </v-avatar>
            </v-chip>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
              style="max-width: 300px"
            ></v-text-field>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on" text color="indigo">
                  {{ selectedStatus }} <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="status in ['Active', 'Deleted']"
                  :key="status"
                  @click="changeStatusFilter(status)"
                  dense
                >
                  <v-list-item-title>{{ status }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn dark class="primary" @click="addVillagehandler">
              <v-icon left dark> mdi-plus </v-icon>
              ADD NEW
            </v-btn>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Add New Village</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row dense>
                        <v-col cols="6">
                          <v-autocomplete
                            dense
                            :items="allStates"
                            v-model="state"
                            item-text="state_name"
                            item-value="id"
                            label="State*"
                            required
                            :return-object="true"
                            :rules="[rules.required('State')]"
                            @change="stateChangeHandler(state.id)"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            :items="allDistricts"
                            v-model="district"
                            item-text="district_name"
                            item-value="id"
                            label="District*"
                            required
                            :return-object="true"
                            :rules="[rules.required('District')]"
                            dense
                            @change="loadMandals(district.id)"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            dense
                            v-model="mandal"
                            label="Mandal*"
                            item-text="mandal_name"
                            item-value="id"
                            :items="allMandals"
                            required
                            :return-object="true"
                            :rules="[rules.required('Mandal')]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            dense
                            v-model="village"
                            label="Village*"
                            required
                            :rules="[rules.required('Village')]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <!-- <v-text-field
                            dense
                            v-model="mpconstituency"
                            label="MP constituency*"
                            required
                            :rules="[rules.required('MP constituency')]"
                          ></v-text-field> -->
                          <v-autocomplete
                            dense
                            v-model="mpconstituency"
                            label="MP constituency*"
                            item-text="mp_constituency_name"
                            item-value="id"
                            :items="allMpConstituencies"
                            required
                            :return-object="true"
                            :rules="[rules.required('MP constituency')]"
                          ></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="6">
                          <v-text-field
                            dense
                            v-model.number="mp_constituency_vote_limit"
                            label="MP constituency vote limit*"
                            required
                            :rules="[
                              rules.required('MP constituency vote limit'),
                            ]"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="12">
                          <!-- <v-text-field
                            dense
                            v-model="mlaconstituency"
                            label="MLA constituency*"
                            required
                            :rules="[rules.required('MLA constituency')]"
                          ></v-text-field> -->
                          <v-autocomplete
                            dense
                            v-model="mlaconstituency"
                            label="MLA constituency*"
                            item-text="mla_constituency_name"
                            item-value="id"
                            :items="allMlaConstituencies"
                            required
                            :return-object="true"
                            :rules="[rules.required('MLA constituency')]"
                          ></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="6">
                          <v-text-field
                            dense
                            v-model.number="mla_constituency_vote_limit"
                            label="MLA constituency vote limit*"
                            type="number"
                            required
                            :rules="[
                              rules.required('MLA constituency vote limit'),
                            ]"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="6">
                          <v-text-field
                            dense
                            v-model.number="wards"
                            label="Ward*"
                            required
                            type="number"
                            :rules="[rules.required('Ward')]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            dense
                            v-model.number="ward_vote_limit"
                            label="Ward vote limit*"
                            type="number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="postData">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            dark
            small
            @click="
              changeStatus(
                'village_code',
                item.village_code,
                item.status,
                mandal_filter
              )
            "
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.state_name="{ item }">
          {{ item.id ? state_filter.state_name : "" }}
        </template>
        <template v-slot:item.mandal_name="{ item }">
          {{ item.mandal_id ? mandal_filter.mandal_name : "" }}
        </template>
        <template v-slot:item.district_name="{ item }">
          {{ item.index ? district_filter.district_name : "" }}
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import rules from "../helpers/validation";
import axios from "axios";
import { baseURL } from "../constant/constant";
// import { HollowDotsSpinner } from "epic-spinners";

export default {
  name: "Home",
  components: {
    // HollowDotsSpinner,
  },
  data: () => ({
    selectedStatus: "Active",
    moment: moment,
    search: "",
    state_filter: "",
    district_filter: "",
    mpcons_filter: "",
    mlacons_filter: "",
    mandal_filter: "",
    rules: rules,
    tableLoading: false,
    dialog: false,
    valid: true,
    state: "",
    district: "",
    mpconstituency: "",
    mlaconstituency: "",
    mp_constituency_vote_limit: "",
    mla_constituency_vote_limit: "",
    ward_vote_limit: "",
    mandal: "",
    village: "",
    wards: 31,
    selectedVillages: [],
    isLoading:false,
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
      },
      {
        text: "Village",
        value: "village_name",
      },
      {
        text: "Mandal",
        value: "mandal_name",
      },
      {
        text: "District Name",
        value: "district_name",
      },
      {
        text: "State Name",
        value: "state_name",
      },
      {
        text: "Status",
        value: "status",
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "allStates",
      "alertType",
      "alertMessage",
      "allDistricts",
      "allMpConstituencies",
      "allMlaConstituencies",
      "allMandals",
      "allVillages",
    ]),
    ...mapState(["response"]),
  },
  mounted() {
    this.$store.commit("set_villages", []);

    this.$store.dispatch("fetchStates");
  },
  methods: {
    ...mapActions([
      "showAlert",
      "fetchStates",
      "fetchDistricts",
      "fetchMPConstituencies",
      "fetchgetMLAConstituencies",
      "fetchMandals",
      "fetchVillages",
      "addNewVillage",
    ]),
    async postData() {
      if (this.$refs.form.validate()) {
        const payload = {
          locations: [
            {
              name: this.village,
              vote_limit: this.ward_vote_limit,
              no_of_wards: this.wards,
              ward_vote_limit: this.ward_vote_limit,
              mla_constituency: this.mlaconstituency.mla_constituency_name,
              mp_constituency: this.mpconstituency.mp_constituency_name,
            },
          ],
          state: this.state.state_name,
          district: this.district.district_name,
          mandal: this.mandal.mandal_name,
        };
        await this.addNewVillage(payload);

        if (this.mandal_filter) {
          await this.loadVillages(this.mandal_filter.id, this.selectedStatus);
        }
        this.closeDialog();
      }
    },
    async changeStatusFilter(status) {
      this.selectedStatus = status;
      await this.loadVillages(this.mandal_filter.id, this.selectedStatus);
    },
    addVillagehandler() {
      if (this.state_filter) {
        this.state = this.state_filter;
         this.stateChangeHandler(this.state_filter.id);
      }
      if (this.district_filter) {
        this.district = this.district_filter;
      }
      if (this.mandal_filter) {
        this.mandal = this.mandal_filter;
      }
      this.dialog = true;
    },
    stateChangeHandler(staeFiltered) {
      this.loadDistricts(staeFiltered);
      this.loadMLAconstituencies(staeFiltered);
      this.loadMPconstituencies(staeFiltered);
    },
    deleteSelectedHandler() {
      this.isLoading=true;
      axios
        .delete(`${baseURL}admin/villages`, {
          data: {
            ids: this.selectedVillages.map((v) => v.id),
          },
        })
        .then(() => {
          this.isLoading=false;
          this.$store.dispatch("showAlert", {
          type: "success",
          message: `Deleted Successfully!`,
        });
        this.selectedVillages=[];
          if (this.mandal_filter) {
            this.loadVillages(this.mandal_filter.id, this.selectedStatus);
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading=false;
          this.$store.dispatch("showAlert", {
          type: "error",
          message: `${err}`,
        });
        });
    },
  },
};
</script>
<style scoped>
.custom-badge {
  background-color: darksalmon;
}
</style>
